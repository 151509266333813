<template>
  <div class="portfolio-page">
    <Page-header
      :cover="require('../assets/images/portfolio-cover.png')"
      :title="$t('ourPortfolio')"
      :desc="$t('portfolioDesc')"
    />
    <Portfolio-sec />
  </div>
</template>

<script>
import PortfolioSec from "../components/Layout/Portfolio.vue";
import PageHeader from "../components/misc/PageHeader.vue";
export default {
  components: { PortfolioSec, PageHeader },
};
</script>
=